<template>
  <section id="feed">
    <home />
  </section>
</template>

<script>
  export default {
    name: 'HomeAbout',

    components: {
      Home: () => import('@/components/Home'),
    },
  }
</script>
